* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./fonts/Raleway/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./fonts/Open_Sans/OpenSans-VariableFont_wdth.ttf) format('truetype');
}





