@use '../../theme' as *;

.overview {
  $self: &;
  background: linear-gradient(95deg, $app-grey 50%, $app-black 50%);
  @include mobile {
    background: linear-gradient(165deg, #D7D7D7 70%, #000 30%);
  }
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position:relative;

  &_bio {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6rem;

    #{ $self }_bio_container {
      height: 100%;
      width: 100%;
      display: flex;
      @include mobile {
        flex-direction: column;
      }
      justify-content: center;
      align-items: center;
      max-width: $app-max-width;
      left: 0;
      right: 0;
      margin: 0 auto;

      #{ $self }_desc {
        flex: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        #{ $self }_desc_container {
          display: flex;
          flex-direction: column;
          font-family: 'Raleway';
          width: 16rem;

          #{ $self }_hi {
            font-weight: 600;
            font-size: 1.5rem;
          }

          #{ $self }_name {
            font-weight: 600;
            font-size: 2.5rem;
            padding-top: 1rem;
          }

          #{ $self }_tagline {
            font-weight: 600;
            color: $app-dark-grey;
            font-size: 0.8rem;
          }

          #{ $self }_social {
            padding-top: 2rem;
            display: flex;

            #{ $self }_social_icon {
              background: #C4C4C4;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              height: 2rem;
              width: 2rem;
              margin-right: 1.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:hover {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
              }

              img {
                height: 60%;
              }
            }
          }
        }
      }

      #{ $self }_image {
        flex: 60%;
        height: 100%;
        @include mobile {
          height: 60%;
        }
        display: flex;
        align-items: end;
        justify-content: center;
        padding-top: 2rem;
        img {
          width: 100%;
          @include mobile {
            height: 100%;
          }
        }
      }
    }

  }
}