@use '../../theme' as *;

.header {
  $self: &;
  height: 4rem;
  position: absolute;
  width: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
  max-width: $app-max-width;
  left: 0;
  right: 0;
  margin: 2rem auto 0 auto;
  padding: 0 2rem;
  z-index: 1;

  &_icon_section {
    flex: 50%;
    height: 100%;

    #{ $self }_icon {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 4rem;
      @include mobile {
        padding-left: 0rem;
      }
      img {
        height: 2.5rem;
      }
    }
  }

  &_menu_section {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    #{ $self }_menu {
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      padding: 0 1rem;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $app-white;
      @include mobile {
        color: $app-black;
      }
      cursor: pointer;

      &:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
      }

      &#{ $self }_menu__white {
        background-color: $app-white;
        border-radius: 2rem;
        color: $app-black;
        @include mobile {
          color: $app-white;
          background-color: $app-black;
        }
      }
    }
  }
}