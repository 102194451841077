$screen-destop-min: 824px;
$screen-mobile-max: 823px;
$app-grey: #D7D7D7;
$app-black: #000;
$app-white: white;
$app-dark-grey: #909090;
$app-max-width: 1200px;

@mixin mobile {
    @media screen and (max-width: #{$screen-mobile-max}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$screen-destop-min}) {
        @content;
    }
}